import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GraphqlService } from "../../shared/services/graphql.service";

@Injectable({ providedIn: 'root' })
export class UserService {
  permissions$: any = [
    { id: 'VIEW_INTOUCH_PROGRESS_ORDERS', name: 'View InTouch Orders' },
    { id: 'UPLOAD_INTOUCH_ARTWORK', name: 'Upload InTouch Artwork' },
    { id: 'REPLACE_INTOUCH_ARTWORK', name: 'Replace InTouch Artwork' },
    { id: 'FETCH_INTOUCH_ORDERS', name: 'Fetch InTouch Orders' },
    { id: 'UPDATE_INTOUCH_ORDERS', name: 'Update InTouch Orders' },

    { id: 'VIEW_AVERY_PROGRESS_ORDERS', name: 'View Avery Orders' },
    // { id: 'VIEW_AVERY_ORDERS', name: 'View Avery Orders' },
    // { id: 'UPDATE_AVERY_ORDERS', name: 'Update Avery Orders' },

    { id: 'VIEW_ORDERS', name: 'View Orders' },

    { id: 'EDIT_INTOUCH_TICKET', name: 'Edit InTouch Ticket' },

    { id: 'VIEW_SETTINGS_MATERIAL_MAP', name: 'View Material Map (Settings)' },
    { id: 'CREATE_SETTINGS_MATERIAL_MAP', name: 'CREATE Material Map (Settings)' },
    { id: 'EDIT_SETTINGS_MATERIAL_MAP', name: 'EDIT Material Map (Settings)' },
    { id: 'DELETE_SETTINGS_MATERIAL_MAP', name: 'DELETE Material Map (Settings)' },
  ]
  constructor(private http: HttpClient, private graphqlService: GraphqlService) {}

  getUserList() {
    const query = `{
      users(param: "all") 
      {   
        id
        username
        permissions
        isAdmin
        isActive
        updatedAt
        createdAt
      }
    }`
    console.log('usersList', query)
    return this.graphqlService.query(query);
  }

  getUser(recordId: any) {
    const query = `{
      user(id: "${recordId}") {
        id
        username
        permissions
        isAdmin
        isActive
        updatedAt
        createdAt
      }
    }`;

    const variables = { }; // Pass variables here
    return this.graphqlService.query(query, variables);
  }

  createUser(jsonData: any) {
    console.log("Data: ", jsonData)
    const variables = { data: jsonData };
    const query = `mutation($data: UserCreateInput!) {
      createUser(user: $data) {
        id
        username 
        permissions
        isAdmin 
        isActive
      }
    }`;

    return this.graphqlService.query(query, variables);
  }

  updateUser(jsonData: any) {
    console.log("Data: ", jsonData)
    const variables = { data: jsonData };
    const query = `mutation($data: UserInput!) {
      updateUser(user: $data) {
        id
        username
        permissions
        isAdmin
        isActive
      }
    }`;

    return this.graphqlService.query(query, variables);
  }
}